import { EvaluatedSubtitleOptions, EvaluatedTemplate } from '@videosmart/player-template';
import { createSelector } from 'reselect';

import { RootState } from '../models';
import { selectEvaluatedTemplate } from './selectEvaluatedTemplate';

export const selectSubtitleOptions = createSelector(
  (state:RootState) => selectEvaluatedTemplate(state),
  (template:EvaluatedTemplate | undefined) => {
    if(template) {
      return template.subtitleOptions;
    } else {
        return { position: "bottom", fontSize: 18, maxFontSize:18 } as EvaluatedSubtitleOptions;
    }
  }
);